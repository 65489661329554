.pageWrapper {
    margin: 0 auto;
    padding: 0;
    margin-bottom: 5.6rem;
    width: 100%;
    min-height: 500px;
}

.centerSpinner {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width:768px) {
    .pageWrapper {
        margin-bottom: 7rem;
    }
}