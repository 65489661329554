.noticeWrapper {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noticeContent {
    padding: 2rem;
    max-width: 30rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid white;
    border-radius: .5rem;
}

.icon {
    font-size: 3.6rem;
}

.title {
    margin: 1rem 0;
    font-size: 2.6rem;
    font-weight: 300;
}

.text{
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 0;
}