#itemWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 1rem 0 ;
    background-color: white;
    padding: 1rem 2rem;
    border-radius: .5rem;
    border: .1rem solid #ccc;
    box-shadow: 0 .1rem .8rem rgba(0, 0, 0, .1);
    transition: .3s;
}

#itemWrapper:hover {
    background-color: whitesmoke;
}
#itemWrapper h1 {
    color: grey;
    margin: 0;
    font-weight: 300;
}

.icon {
    font-size: 2rem;
    color: var(--primary);
    margin-right: 2rem;
}