.pollItemWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 300px;
    max-width: 100vw;
    transition: .3s;
}

.pollBodyContiner {
    padding:  2rem;
    padding-left: 0;
    min-width: 90%;
    display: flex;
}

.footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: #fff;
    border-radius: 0 0 .5rem .5rem ;
    
}

.headerMenuBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.showMenuButton {
    padding: 0 1rem;
    height: 2rem;
    font-size: 3rem;
    color: #fff;
    width: fit-content;
    cursor: pointer;
}

.menuRefresh {
    padding: 1rem;
    font-size: 2rem;
    color: #fff;
    width: fit-content;
    cursor: pointer;
    transition: 1s;
}

.menuRefresh:hover {
    transform: rotate(130deg);
}

.poll-item-menu {
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    transition: .3s;
    height: 0;
}

.pim-open {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
    background-color: rgba(0, 0, 0, .9);
    z-index: 500;
}

.pim-actions {
    display: flex;
    justify-content: space-around;
}

.pollItemLoading {
    position: absolute;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.pollItemLoading-dots {
    height: 1.6rem;
}
.pollItemLoadingDots div {
    background-color: var(--primary);
}

.LoadingDotsH1, .LoadingDotsP {
    margin: 0;
    color: white;
}