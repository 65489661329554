
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');

html {
  margin: 0;
  position: relative;
  min-height: 100vh;
  font-size: 62.5%;
}

body {
  margin: 0; 
  min-height: 650px;
  font-family: 'Red Hat Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  color: #c3c3c3;
  background-image: url('https://myevent.eventstreams.co/img/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 2;
}

:root {
  --primary-100: #FF5A5F;
  --primary: #C21807;
  --primary-500: #7E191B;

  --secondary-100: #EEEEEE;
  --secondary: #333;
  --secondary-500: #434343;

  --input-color: #b0b0b0;
  --input-border: #d2d2d2;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;

  --input-border-focus: #C21807;

  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #ffeeee;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: var(--primary);
}

a {
  color: var(--primary);
}

a:hover {
  color: var(--primary-500);
}

.helogale {
  color: white;
  text-decoration: none;
  opacity: .6;
}

.center {
  margin: 0 auto;
  text-align:  center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.left {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1750px;
}

.page-wrapper {
  margin: 0 auto;
  padding:0;
  margin-bottom: 5.6rem;
  width: 100%;
  min-height: 500px;
}

.header-container {
  position: fixed;
  z-index: 500 !important;
}

@media only screen and (max-width:768px) {
  main {
    margin-top: 5rem;
  }
}