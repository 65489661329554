.votingWrapper {
    display: flex;
    justify-content: center;
    color: var(--primary);
    min-width: 25rem;
}

.buttonContainer {
    margin: 0;
    display: flex;
    flex-direction: column;
}

.voteText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.voteBtnContainer {
display: flex;
align-items: center;
}


.voteButton {
    margin: .5rem;
    flex-grow: 2;
}
.voteValue {
    width: 15%;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
}

.thanksContainer {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.check {
    font-size: 8rem;
    color: green;
    background-color: white;
    border-radius: 50%;
    line-height: 7rem;
    margin: 2rem;
}

.cross {
    font-size: 8rem;
    color: red;
    background-color: white;
    border-radius: 50%;
    line-height: 7rem;
    margin: 2rem;
}

.message {
    font-size: 2rem;
    color: white;
    text-align: center;
    font-weight: 200;
    margin: .2rem;
}

.subMessage {
    font-size: 1.4rem;
    text-align: center;
    margin: 0;
    margin-bottom: 3rem;
    color: var(--primary);
}

.votedValue {
    margin-top: 0;
    padding: 0 2rem;
    color: white;
    font-size: 2rem;
}

.contentContainerWrapper {
    width: 100%;
    background-color: transparent !important;
    color: white;
    border: 0 !important;
}
.thanksContainer {
    color: white;
}