#qaBody{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.noQuestionsWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: center;
    align-items: center;

}

.noQuestionsContent {
    margin: 0;
    padding: 2rem;
    border: 0px solid white;
    border-radius: .5rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

}

.icon {
    font-size: 4rem;
}

.title {
    font-size: 2rem;
    margin-bottom: 0;
}

.text {
    margin-top: .2rem;
    color: var(--primary);
    font-size: 1.6rem;
}