#dot {
    margin: .3rem 1rem;
    margin-right: 0;
    position: relative;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
} 

.blinking {
    -webkit-animation: 1s blink 1s ease infinite;
    -moz-animation: blink ease 1s infinite;
    -ms-animation:  blink 1s ease infinite;
    -o-animation:  blink 1s ease infinite;
    animation: blink 1s  ease infinite;
    
  }
  
  @keyframes blink {
    from, to {
      opacity: 0;
      visibility: hidden;
    }
    50% {
      opacity: 1;
      visibility: visible;
    }
  }
  
  @-moz-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  @-ms-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  @-o-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  } 