#hero {
    width: 100%;
    min-height: 50rem;
    margin: 0;
    padding: 0;
   background-position: center;
   background-repeat: no-repeat;background-size: cover;
   position: relative;
   display: flex;
}

#bgOverlay {
 position: absolute;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
 background-color: var(--primary);
 opacity: .7;
 z-index: 1;
}

.content {
    margin: 0 auto;
    position: relative;
    z-index: 2;
    max-width: 192rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.title {
    margin-bottom: 0;
    font-size: 5vw;
    line-height: 5.1vw;
    color: white;
    font-weight: 900;
}

.subTitle {
    margin: 0;
    padding: 0;
    font-size: 3vw;
    line-height: 3.1vw;
}


.buttonSecondary {
    margin: 2vw;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    border-width: 1px;
    font-weight: 600;
    font-size: 1vw;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    transition: all .35s;
    color: #212529 !important;
    background-color: #f0b505;
    border: .1rem solid #7B6715;
    box-shadow: 0 .3rem .3rem rgba(0, 0, 0, .4);
    
}

.buttonSecondary span {
    margin-left: 1rem;
}

.buttonSecondary:hover {
    box-shadow: 0 .6rem .6rem rgba(0, 0, 0, .2);
    background-color: #ffcc00;
    transform: scale(1.1);
  }

  .text {
      color: white;
      font-size: 2rem;
      max-width: 50vw;
      text-align: center;
      font-weight: 400;
      margin-bottom: 5rem;
  }


  @media  screen and (max-width:1920px) {
    .buttonSecondary {
        font-size: 2rem;
    }
  }

  @media  screen and (max-width:768px) {
    .buttonSecondary {
        font-size: 2rem;
    }

    .title {
        font-size: 6vw;
        line-height: 6.1vw;
    }
    .subTitle {
        font-size: 4vw;
        line-height: 4.1vw;
    }
  }


  @media  screen and (max-width:568px) {
    .buttonSecondary {
        width: 95%;
    }
    .title {
        font-size: 8vw;
        line-height: 8.1vw;
    }
    .subTitle {
        font-size: 6vw;
        line-height: 6.1vw;
    }

    .text {
        max-width: 95vw;
        font-size: 1.4rem;
    }
  }