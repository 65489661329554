.itemWrapper {
    margin: 1rem;
    padding: 1rem;
    background-color: var(--primary);
    color: white;
    border-radius: .5rem;
    opacity: .8;
    transition: .3s;
    cursor: pointer;
}

.itemWrapper:hover {
   opacity: 1;
}
   