#countdownWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    color: white;
    font-size: 2.5vw;
    width: 100%;
    margin-top: 2rem;
}

#countdownWrapper span {
    margin-top: .5vw;
}

.counter {
    text-align: center;
    margin: 0;
   width: 4vw;
   text-align: center;
   width: 100%;
}

.counterLabel {
    margin: 0;
    text-align: center;
    font-size: 1vw;
}

.days,
.hours,
.mins,
.secs {
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 5.5vw;
}

@media  screen and (max-width:768px) {
    #countdownWrapper {
        font-size: 4.5vw;
    }

    #countdownWrapper span {
        margin-top: 1vw;
    }

    .days,
    .hours,
    .mins,
    .secs {
        width: 8.5vw;
    }
  }


  @media  screen and (max-width:568px) {
   
  }