#qWrapper {
    margin:  0;
    padding: 0;
    display: flex;
   justify-content: flex-start;
    
}

#leftBodyWrapper, #rightActionsWrapper  {
    display: flex;
    flex-direction: column;
}

#leftBodyWrapper {
    width: 50rem;
    padding: 1rem 0;
}

#rightActionsWrapper {
   display: flex;
}


.qContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    
}



.qBody {
    display: flex;
    width: fit-content;
    max-width: 70%;
    margin: 1rem 1rem 1rem 0  ;
    padding: 1rem 2rem;
    padding-bottom: 2.2rem;
    width: fit-content;
    background-color: rgba(255, 255, 255, .6);
    border-radius: 0 1rem 1rem 0;
    color: white;
    font-size: 1.4rem;
}

.userQuestion {
    background-color: rgba(100, 255, 100, .6);
}

.qBodyContent {
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.qBodyContent h1 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 600;
}

.qActions {
    padding: 0 1rem;
    display: flex;
    width: fit-content;
    justify-self: flex-start;
    align-items: flex-start;
}



.qInfo {
    margin-top: -.9rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-self: flex-start;
    color: rgba(0, 0, 0, .6);
    padding-top: .5rem;
}

.qAuthor {
    display: flex;
   
}
.qTimestamp {
    display: flex;
    margin-left: 1rem;
}

.qAuthor p, .qTimestampText {
    font-weight: 300;
    margin-top: .3rem;
}



.notificationMessageContainer {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    
}
.notificationMessage {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: fit-content;
    background-color: white;
    border-radius: 1rem;
}

.notificationMessage p {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 400;
    padding: .4rem 1rem;
    color: #333;
}