
.authWrapper {
    margin: 10% 0 0 0;
    width: 90%;
    max-width: 500px;
}


.ticketInput {
    text-align: center;
    font-size: 3rem;
    font-weight: 900;
    text-transform: uppercase;
    color: #333;
}

.logo {
    width: 12rem;
}

.title {
    font-size: 2.6rem;
    color: var(--primary);
    margin-top: .5rem;
}

.actions {
    display: flex;
    margin-bottom: 3rem;
    width: 100%;
}

.altContainer {
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 1 1 auto;
}

.altContainerText {
    margin: 0;
    padding: 0;
    width: fit-content;
    font-size: 1.4rem;
}

.enterBtn {
    font-size: 1.9rem;
    flex: 1 1 auto;
    margin: 0 10%;
}

.regBoxWrapper {
    margin: 0 10%;
    width: 100%;
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
}

.registerBox {
    flex: 1 1 auto;
}

.registerBtn {
    margin: 0 2rem;
    padding: 1rem 2rem;
    font-size: 2rem;
    font-weight: 400;
}

@media screen and (max-width: 624px) {

    .authWrapper {
        padding-top: 15%;
        width: 100%;
        max-width: 100%;
    }

    .regBoxWrapper {
        margin: 0;
        border-radius: 0;
    }

    .altContainer {
        flex-direction: column;
        justify-content: space-around;
        min-height: 12rem;
        padding: 1rem 10%;
    }

    .altContainerText {
        font-size: 1.6rem;
        font-weight: 600;
    }

    .registerBtn {
        margin: 0 10%;
        width: 100%;
    }
    

}