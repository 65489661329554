.ListWrapper {
    margin: 1rem;
}

.agendaItemWrapper {
    margin: 2rem 0;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 1);
    color: white;
}

.imageWrapper {
    display: flex;
    align-items: flex-start;
    justify-content:  flex-start;
    margin: 1rem;
    margin-bottom: 0;
}

.image {
    width: 5rem;
    height: 5rem;
    background-color: var(--primary);
    border-radius: 50%;
}

.headerInfoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.itemHeader {
    margin: 0;
    padding: 0;
    display: flex;

}

.title {
    font-weight: 600;
    color: #333;
    margin: 0;
}

.subtitle {
    font-weight: 600;
    color: var(--primary);
    margin: 0;
}

.timesWrapper {
    margin: 1rem 0 0rem 0;
    background-color: white;
    color: #333;
    padding: .5rem;
    font-size: 1.4rem;
}

.times {
    color: #333;
    margin-left: 1rem;
}

.times span {
    color: var(--primary);
    font-weight: 500;
    margin-left: 1rem;
}

.description {
    margin: 0;
    border-top: 1px #ccc solid;
    padding: 1rem;
}

.desc {
    font-size: 1.4rem;
    color: #333;
}