.video {
  width: 50%;
 }

 .spinnerWithText {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinnerWithText p {
  font-size: 1.2rem;
  color: #333;
}