#backWrapper {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;
}

#backWrapper a {
    text-align: left;
    font-size: 1.4rem;
    font-weight: 400;
  }
  