@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap);

html {
  margin: 0;
  position: relative;
  min-height: 100vh;
  font-size: 62.5%;
}

body {
  margin: 0; 
  min-height: 650px;
  font-family: 'Red Hat Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  color: #c3c3c3;
  background-image: url('https://myevent.eventstreams.co/img/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 2;
}

:root {
  --primary-100: #FF5A5F;
  --primary: #C21807;
  --primary-500: #7E191B;

  --secondary-100: #EEEEEE;
  --secondary: #333;
  --secondary-500: #434343;

  --input-color: #b0b0b0;
  --input-border: #d2d2d2;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;

  --input-border-focus: #C21807;

  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #ffeeee;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: var(--primary);
}

a {
  color: #C21807;
  color: var(--primary);
}

a:hover {
  color: #7E191B;
  color: var(--primary-500);
}

.helogale {
  color: white;
  text-decoration: none;
  opacity: .6;
}

.center {
  margin: 0 auto;
  text-align:  center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.left {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1750px;
}

.page-wrapper {
  margin: 0 auto;
  padding:0;
  margin-bottom: 5.6rem;
  width: 100%;
  min-height: 500px;
}

.header-container {
  position: fixed;
  z-index: 500 !important;
}

@media only screen and (max-width:768px) {
  main {
    margin-top: 5rem;
  }
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 3px solid var(--primary);
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.input-wrapper {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
}
.form-field {
    display: block;
    width: 100%;
    padding: .8rem 1.6rem;
    line-height: 2.5rem;
    font-size: 1.6rem;
    font-weight: 500;
    font-family: inherit;
    border-radius: 6px;
    -webkit-appearance: none;
    color: var(--input-color);
    border: 1px solid var(--input-border);
    background: var(--input-background);
    transition: border .3s ease; 
}

.no-group {
    border-radius: 6px!important;
}

.form-field::-webkit-input-placeholder {
    color: var(--input-placeholder);
}

.form-field:-ms-input-placeholder {
    color: var(--input-placeholder);
}

.form-field::placeholder {
    color: var(--input-placeholder);
}

.form-field:focus {
    outline: none;
    border-color: var(--input-border-focus);
}

.form-group {
    margin: 0;
    position: relative;
    display: flex;
    width: 100%;
}

.form-padding {
    margin: 0 10%;
}

.form-group span, .form-group .form-field {
    white-space: nowrap;
    display: block;
    border-radius: 6px;
}

.form-group .form-field:not(:first-child):not(:last-child),
.form-group span:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.form-group .form-field:first-child,
.form-group span:first-child {
    border-radius: 6px 0 0 6px;
}

.form-group .form-field:last-child,
.form-group span:last-child{
    border-radius: 0 6px 6px 0;
}

.form-group .form-field:not(:first-child),
.form-group .span:not(:first-child) {
    margin-left: -.1rem;
}


.form-group .form-field {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
}

.form-group span {
    text-align: center;
    padding: .8rem 1.2rem;
    font-size: 1.4rem;
    line-height: 2.5rem;
    color: var(--group-color);
    background: var(--group-background);
    border: 1px solid var(--group-border);
    transition: background .3s ease, border .3s ease, color .3s ease;
}

.form-group:focus-within span {
        color: var(--group-color-focus);
        background: var(--group-background-focus);
        border-color: var(--group-border-focus);
    }

   

   .inputError {
       
        color: darkred;
        font-weight: 600;
    
}
.Button_button__3HrTv {
    margin: 1.5rem;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    border-width: 1px;
    background-color: var(--primary);
    border: 1px solid var(--primary-500);
    color: white;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    transition: all .35s;
}

.Button_button__3HrTv span{
    position: relative;
    z-index: 2;
  }

.Button_button__3HrTv:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: var(--primary-500);
    transition: all .35s;
    border-radius: 5px;
  }

.Button_button__3HrTv:hover:after {
    width: 100%;
  }

.Button_button__3HrTv:focus, .Button_button__3HrTv:active {
    border: 1px solid white;
    outline: none;
}

.Button_button40__3Gt8- {
    width: 40%;
}

.Button_button50__2c_7Z {
    width: 50%;
}

.Button_button100__1VVSc {
    width: 100%;
}

.Button_buttonPrimary__2t0Mj {
    background-color: var(--primary);
    border: 1px solid var(--primary-500);
    color: white;
}
.Button_buttonPrimary__2t0Mj:after{
    background-color: var(--primary-500);
}


.Button_buttonSecondary__8NtIy {
    background-color: var(--secondary);
    border: 1px solid var(--secondary-500);
    color: white;
}
.Button_buttonSecondary__8NtIy:after{
    background-color: var(--secondary-500);
}

.Button_buttonDanger__LE3E4 {
    background-color: #B70F0F;
    border: 1px solid #D53D3D;
    color: white;
}
.Button_buttonDanger__LE3E4:after{
    background-color: #770808;
}

.Button_buttonGreen__2nFCi {
    background-color: #168612;
    border: 1px solid #10640d;
    color: white;
}
.Button_buttonGreen__2nFCi:after{
    background-color: #10640d;
}

.Button_buttonWarning__QclYI {
    color: #212529;
    background-color: #ffc107;
    border-color: #7B6715;
}
.Button_buttonWarning__QclYI:after{
    background-color: #e0a800;
}

.Button_buttonOutlinePrimary__3pTy0{
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
}
.Button_buttonOutlinePrimary__3pTy0:after{
    background-color: white;
}

.Button_buttonOutlineWhite__3MEka{
    background-color: transparent;
    border: 1px solid white;
    color: white;
}
.Button_buttonOutlineWhite__3MEka:after{
    background-color: white;
}
.Button_buttonOutlineWhite__3MEka:hover{
    color: black;
}

.Button_button-white__1OJ8_{
    color: black;
    background-color: white;
    border: 1px solid white;
}
.Button_button-white__1OJ8_:hover {
    color: white;
}
.Button_button-white__1OJ8_:after{
    background-color: black;   
}

.Button_disabled__1pUqo {
    background-color: gray;
    color: darkgray;
    border: 1px solid darkgray;
}

.Button_disabled__1pUqo:after {
    background-color: gray;
}

.Button_button__3HrTv a {
    color: white;
    position: relative;
    z-index: 2;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    font-size: 2rem;
    width: 100%;
}




.ErrorModal_title__3PEPV {
    font-size: 3rem;
    margin-bottom: 0;
}

.ErrorModal_text__1CaIf {
    max-width: 40rem;
    color: #333;
}
.LoginForm_formWrapper__EZfyu {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 50rem;
    margin-left: 2rem;
}

.LoginForm_form__3OBi7 {
    width: 100%;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.LoginForm_formWrapper__EZfyu form label {
    color: var(--primary);
    font-size: 1.4rem;
    text-align: left;
}

.LoginForm_formTitle__3oV3r {
    color: var(--primary);
    text-align: center;
    font-size: 3rem;
    margin: 1.8rem 0;
    border-radius: .5rem;
}

.LoginForm_input__3AKbo {
    background-color: transparent;
    outline: none;
    border-radius: 0 !important;
    border: 0;
    border-bottom: 1px solid #333;
    color: #333;
}

.LoginForm_inputWrapper__3Kp3F {
    display: flex;
    flex-direction: column; 
}

.LoginForm_actions__3a0pM {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 100%;
}

.LoginForm_enterBtn__2Yc2x, .LoginForm_registerBtn__2Ep3r {
    font-size: 1.9rem;
    width: 45%;
    margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #333 !important;
}

.LoginForm_enterBtn__2Yc2x {
    color: white;
}

.LoginForm_registerBtn__2Ep3r {
    background-color: #333;
    color: #fff;
    border: rgb(190, 190, 190);
}

.LoginForm_registerBtn__2Ep3r:after {
    background-color:rgb(190, 190, 190);
    color: #000;
}

.LoginForm_errorInputLabel__38Nrz {
    margin-left: 1rem;
    color: red;
    background-color: rgba(500, 10, 10, .2);
    transition: .3s;
    padding: 0 1rem;
    padding-top: .5rem;
    border-radius: .5rem .5rem 0 0;
}

.LoginForm_errorInput__8-O0j {
    background-color: rgba(500, 10, 10, .2);
    transition: .3s;
}

.LoginForm_noError__3LrY6 {
    background-color: rgba(500, 10, 10, .0) !important;
}

.LoginForm_backbtnWrapper__3_8hK {
    display: flex;
    justify-content: flex-start;
}

.LoginForm_backbtnWrapper__3_8hK a {
  text-align: left;
  font-size: 1.4rem;
  font-weight: 400;
}


@media screen and (max-width: 624px) {

    .LoginForm_formTitle__3oV3r {
        margin-top: 0;
        border-radius: 0;
    }

    .LoginForm_formWrapper__EZfyu {
        width:100%;
        max-width: none;
        margin-left: 0;
        height: 60vh;
    }

    .LoginForm_form__3OBi7 {
        width: 90%;
        padding: 0 5%;
        justify-content: space-between;
    }

    .LoginForm_inputWrapper__3Kp3F {
        padding-top: 2rem;
    }

}
.RegisterForm_formWrapper__1_MXP {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 50rem;
    margin-left: 2rem;
}

.RegisterForm_form__1FOTa {
    width: 100%;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.RegisterForm_formWrapper__1_MXP form label {
    color: var(--primary);
    font-size: 1.4rem;
    text-align: left;
}

.RegisterForm_formTitle__2XyIH {
    color: var(--primary);
    text-align: center;
    font-size: 3rem;
    margin: 1.8rem 0;
    border-radius: .5rem;
}

.RegisterForm_input__3wIAW {
    background-color: transparent;
    outline: none;
    border-radius: 0 !important;
    border: 0;
    border-bottom: 1px solid #333;
    color: #333;
}

.RegisterForm_inputWrapper__3pffL {
    display: flex;
    flex-direction: column; 
}

.RegisterForm_actions__1paqc {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 100%;
}

.RegisterForm_enterBtn__2g0jz, .RegisterForm_registerBtn__1XRDY {
    font-size: 1.9rem;
    width: 45%;
    margin: 0;
   
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #333 !important;
}

.RegisterForm_enterBtn__2g0jz {
    color: white;
}

.RegisterForm_registerBtn__1XRDY {
    background-color: white;
    color: #000;
    border: rgb(190, 190, 190);
}

.RegisterForm_registerBtn__1XRDY:after {
    background-color:rgb(190, 190, 190);
    color: #000;
}

.RegisterForm_submittedContiner__3-OqW {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.RegisterForm_submittedTitle__2VnoF {
    font-size: 3rem;
    margin: 1rem 0;
    font-weight: 400;
    color: #333;
}

.RegisterForm_submittedEmail__102Ky {
    color: var(--primary);
    font-size: 2.4rem;
    margin: .5rem 0;
    font-weight: 300;
}

.RegisterForm_submittedText__3rf32 {
    font-size: 1.6rem;
    margin: 1rem 0;
    display: block;
    color: #333;
}

.RegisterForm_registeredBtn__18QMd {
    color: black;

}

.RegisterForm_errorInputLabel__SX2CQ {
    margin-left: 1rem;
    color: red;
    background-color: rgba(500, 10, 10, .2);
    transition: .3s;
    padding: 0 1rem;
    padding-top: .5rem;
    border-radius: .5rem .5rem 0 0;
}

.RegisterForm_errorInput__22n3c {
    background-color: rgba(500, 10, 10, .2);
    transition: .3s;
}

.RegisterForm_noError__4PxtJ {
    background-color: rgba(500, 10, 10, .0) !important;
}

.RegisterForm_backbtnWrapper__Oez-H {
    display: flex;
    justify-content: flex-start;
}

.RegisterForm_backbtnWrapper__Oez-H a {
  text-align: left;
  font-size: 1.4rem;
  font-weight: 400;
}


@media screen and (max-width: 624px) {

    .RegisterForm_formTitle__2XyIH {
        margin-top: 0;
        border-radius: 0;
    }

    .RegisterForm_formWrapper__1_MXP {
        width:100%;
        max-width: none;
        margin-left: 0;
        height: 60vh;
    }

    .RegisterForm_form__1FOTa {
        width: 90%;
        padding: 0 5%;
        justify-content: space-between;
    }

    .RegisterForm_inputWrapper__3pffL {
        padding-top: 2rem;
    }

}
#LangSelReg_LangSelReg__2a4pU {
    position: relative;
    margin: 0;
    margin-bottom: 2rem;
    padding: 2rem;
    padding-top: 3rem;
   color: #333;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    border-radius: 1rem;
    
}

.LangSelReg_itemWrapper__LuEUM {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 15rem;
    transition: .3s;
    cursor: pointer;
}

.LangSelReg_itemWrapper__LuEUM:hover {
    color: var(--primary);
}

.LangSelReg_iconWrapper__27PYO {
    color: var(--primary);
    padding: 1rem;
    font-size: 1.5rem;
}

.LangSelReg_titleWrapper__2myxf {
    
}

.LangSelReg_title__36CiN {
    font-weight: 300;
    font-size: 1.8rem;
}

.LangSelReg_instruction__1CqlX {
    position: absolute;
    top:0;
    left: 2rem;
    width: 100%;
    font-size: 1.2rem;
    color: var(--primary);
    text-align: left;
}

.LangSelReg_inputError__38RtZ {
    background-color: red !important;
}

@media only screen and (max-width:768px) {
    #LangSelReg_LangSelReg__2a4pU {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

#BackButton_backWrapper__2GVDE {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;
}

#BackButton_backWrapper__2GVDE a {
    text-align: left;
    font-size: 1.4rem;
    font-weight: 400;
  }
  
.ForgotPassword_formWrapper__1PJFK {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 50rem;
    margin-left: 2rem;
}

.ForgotPassword_form__2wuJG {
    width: 100%;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.ForgotPassword_formWrapper__1PJFK form label {
    color: var(--primary);
    font-size: 1.4rem;
    text-align: left;
}

.ForgotPassword_formTitle__31xnl {
    color: var(--primary);
    text-align: center;
    font-size: 3rem;
    margin: 1.8rem 0;
    border-radius: .5rem;
}
.ForgotPassword_input__1HyeF {
    background-color: transparent;
    outline: none;
    border-radius: 0 !important;
    border: 0;
    border-bottom: 1px solid #333;
    color: #333;
}

.ForgotPassword_inputWrapper__11tZX {
    display: flex;
    flex-direction: column; 
}

.ForgotPassword_actions__3Jryh {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 100%;
}

.ForgotPassword_enterBtn__2kf27, .ForgotPassword_registerBtn__1qFPt {
    font-size: 1.9rem;
    width: 45%;
    margin: 0;
   
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #333 !important;
}

.ForgotPassword_enterBtn__2kf27 {
    color: white;
}

.ForgotPassword_sentWrapper__2ZDV7 {
    margin: 2rem 0 ;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ForgotPassword_sentText__39lZy {
    margin: 0 ;
    padding: 0;
    font-size: 1.8rem;
    color: #333;
    font-weight: 300;
    text-align: center;
    white-space: pre-wrap;
}

.ForgotPassword_errorInputLabel__15spY {
    margin-left: 1rem;
    color: red;
    background-color: rgba(500, 10, 10, .2);
    transition: .3s;
    padding: 0 1rem;
    padding-top: .5rem;
    border-radius: .5rem .5rem 0 0;
}

.ForgotPassword_errorInput__3YRQm {
    background-color: rgba(500, 10, 10, .2);
    transition: .3s;
}

.ForgotPassword_noError__3PaG- {
    background-color: rgba(500, 10, 10, .0) !important;
}

.ForgotPassword_backbtnWrapper__64C7j {
    display: flex;
    justify-content: flex-start;
}

.ForgotPassword_backbtnWrapper__64C7j a {
  text-align: left;
  font-size: 1.4rem;
  font-weight: 400;
}

@media screen and (max-width: 1018px) {
    .ForgotPassword_formTitle__31xnl {
        font-size: 3vw;
        
    }
}

@media screen and (max-width: 624px) {

    .ForgotPassword_formTitle__31xnl {
        font-size: 5vw;
        margin-top: 0;
        border-radius: 0;
    }

    .ForgotPassword_formWrapper__1PJFK {
        width:100%;
        max-width: none;
        margin-left: 0;
        height: 60vh;
    }

    .ForgotPassword_form__2wuJG {
        width: 90%;
        padding: 0 5%;
        justify-content: space-between;
    }

    .ForgotPassword_inputWrapper__11tZX {
        padding-top: 2rem;
    }

}
.ResetPassword_formWrapper__3RQ1v {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 50rem;
    margin-left: 2rem;
}

.ResetPassword_form__30NF_ {
    width: 100%;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
}

.ResetPassword_formWrapper__3RQ1v form label {
    color: var(--primary);
    font-size: 1.4rem;
}

.ResetPassword_formTitle__YYWoH {
    color: var(--primary);
    text-align: center;
    font-size: 3rem;
    margin: 1.8rem 0;
    border-radius: .5rem;
}

.ResetPassword_input__3kgHS {
    background-color: transparent;
    outline: none;
    border-radius: 0 !important;
    border: 0;
    border-bottom: 1px solid #ccc;
    color: #333;
}

.ResetPassword_inputWrapper__2iMbv {
    display: flex;
    flex-direction: column; 
}

.ResetPassword_actions__MteCn {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 100%;
}

.ResetPassword_enterBtn__2X5Ej, .ResetPassword_registerBtn__27J5a {
    font-size: 1.9rem;
    width: 45%;
    margin: 0;
   
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #333 !important;
}

.ResetPassword_enterBtn__2X5Ej {
    color: black;
}

.ResetPassword_registerBtn__27J5a {
    background-color: white;
    color: #000;
    border: rgb(190, 190, 190);
}

.ResetPassword_registerBtn__27J5a:after {
    background-color:rgb(190, 190, 190);
    color: #000;
}

.ResetPassword_sentWrapper__2Mw1e {
    margin: 2rem 0 ;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ResetPassword_sentText__3p4HS {
    margin: 0 ;
    padding: 0;
    font-size: 1.8rem;
    color: #333;
    font-weight: 300;
    text-align: center;
}

.ResetPassword_errorInputLabel__1Q5Iy {
    margin-left: 1rem;
    color: red;
    background-color: rgba(500, 10, 10, .2);
    transition: .3s;
    padding: 0 1rem;
    padding-top: .5rem;
    border-radius: .5rem .5rem 0 0;
}

.ResetPassword_errorInput__12P0g {
    background-color: rgba(500, 10, 10, .2);
    transition: .3s;
}

.ResetPassword_noError__16GNs {
    background-color: rgba(500, 10, 10, .0) !important;
}

.ResetPassword_invalidMessage__1Wbjv {
    font-size: 1.8rem;
    color: white;
    display: block;
}

.ResetPassword_successWrapper__3f3K1 {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ResetPassword_successTitle__-xbcl {
    margin: 0;
    padding: 0;
    color: var(--primary);
    font-size: 4rem;
    font-weight: 400;
}

.ResetPassword_successText__S_i9b {
    font-size: 1.8rem;
    font-weight: 400;
    margin: 0;
    white-space: pre-wrap;
    text-align: center;
}

.ResetPassword_backbtnWrapper__1YPdq {
    display: flex;
    justify-content: flex-start;
}

.ResetPassword_backbtnWrapper__1YPdq a {
  text-align: left;
  font-size: 1.4rem;
  font-weight: 400;
}

@media screen and (max-width: 1018px) {
    .ResetPassword_formTitle__YYWoH {
        font-size: 3vw;
        
    }
}

@media screen and (max-width: 624px) {

    .ResetPassword_formTitle__YYWoH {
        font-size: 5vw;
        margin-top: 0;
        border-radius: 0;
    }

    .ResetPassword_formWrapper__3RQ1v {
        width:100%;
        max-width: none;
        margin-left: 0;
        height: 60vh;
       
    }

    .ResetPassword_form__30NF_ {
        width: 90%;
        padding: 0 5%;
        justify-content: space-between;
    }

    .ResetPassword_inputWrapper__2iMbv {
        padding-top: 2rem;
    }

}

.AuthTicket_authWrapper__1ypik {
    margin: 10% 0 0 0;
    width: 90%;
    max-width: 500px;
}


.AuthTicket_ticketInput__9-AZA {
    text-align: center;
    font-size: 3rem;
    font-weight: 900;
    text-transform: uppercase;
    color: #333;
}

.AuthTicket_logo__1bXkW {
    width: 12rem;
}

.AuthTicket_title__j0J95 {
    font-size: 2.6rem;
    color: var(--primary);
    margin-top: .5rem;
}

.AuthTicket_actions__3T92b {
    display: flex;
    margin-bottom: 3rem;
    width: 100%;
}

.AuthTicket_altContainer__QIXyp {
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 1 1 auto;
}

.AuthTicket_altContainerText__2p6tJ {
    margin: 0;
    padding: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1.4rem;
}

.AuthTicket_enterBtn__1IPJT {
    font-size: 1.9rem;
    flex: 1 1 auto;
    margin: 0 10%;
}

.AuthTicket_regBoxWrapper__AakpW {
    margin: 0 10%;
    width: 100%;
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
}

.AuthTicket_registerBox__2VWI6 {
    flex: 1 1 auto;
}

.AuthTicket_registerBtn__1Br70 {
    margin: 0 2rem;
    padding: 1rem 2rem;
    font-size: 2rem;
    font-weight: 400;
}

@media screen and (max-width: 624px) {

    .AuthTicket_authWrapper__1ypik {
        padding-top: 15%;
        width: 100%;
        max-width: 100%;
    }

    .AuthTicket_regBoxWrapper__AakpW {
        margin: 0;
        border-radius: 0;
    }

    .AuthTicket_altContainer__QIXyp {
        flex-direction: column;
        justify-content: space-around;
        min-height: 12rem;
        padding: 1rem 10%;
    }

    .AuthTicket_altContainerText__2p6tJ {
        font-size: 1.6rem;
        font-weight: 600;
    }

    .AuthTicket_registerBtn__1Br70 {
        margin: 0 10%;
        width: 100%;
    }
    

}
#OnDemand_onDemand__5ySAN {
    display: flex;
}
.branding {
    width: 100% ;
    height: 100%;
    display: flex; 
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }

  .branding_inner_wrapper {
    width: 100%;
    max-width: 110rem;
    display: flex;
    justify-content: flex-start;
  }
  
  .branding .branding_inner_wrapper img {
    display: block;
    padding: 2px;
    width: 100%;
    height: 100%;
    object-fit: contain;
   
    
  }

  .branding_inner_title {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .branding_inner_title h1 {
    margin: 0;  
    font-size: 3rem;
    color: var(--primary);
  }

  .branding_inner_title h2 {
    margin: 0;  
    font-size: 2rem;
    color: var(--secondary);
  }
#LiveStream_liveStream__1B1sJ {
    max-height: 60rem;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem .1rem;
    background-color: black;
    
}

#LiveStream_video__4zeU6 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
   
}

.LiveStream_videoContainer__3gzK1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 54vw;
    max-width: 106.6rem;
    max-height: 60rem;
    
}

#LiveStream_videoPlayerWrapper__3VRS6 {
    width: 100%;
    height: 100%;
}

.LiveStream_player__3Lp6t {
    width: 100vw;
    max-width: 106.6rem;
    height: 50vw;
    max-height: 90vh;
}

.LiveStream_playbtn__3QpQF, .LiveStream_fullscreenBtn__KQxFh {
    position: absolute;
    bottom: 1.5rem;
    left: 1%;
    background-color: var(--primary);
    border: 0px solid var(--primary-500);
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    color: white;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .5;
    transition: .3s;
    box-shadow: 0 .3 .3 rgba(0, 0, 0, .4);
}

.LiveStream_fullscreenBtn__KQxFh {
    height: 4rem;
    width: 4rem;
    left: calc(1% + 6rem);
}

.LiveStream_playbtn__3QpQF:hover, .LiveStream_fullscreenBtn__KQxFh:hover {
    opacity: 1;
}


@media (min-width:1920px) {
    .LiveStream_marquee__29GKm {
        font-size: 1.8rem;
    }

}
@media (min-width:1340px) {
    .LiveStream_marquee__29GKm {
        font-size: 1.6rem;
    }
}

@media (max-width:1340px) {
    #LiveStream_liveStream__1B1sJ {
       flex-direction: column;
    }
    #LiveStream_video__4zeU6, #LiveStream_info__fMcO3 {
        
        width:95%;
    } 
}


.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 33px;
  }
  .lds-ellipsis div {
    position: absolute;
    top:0px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .loading-dots-text {
    color: white;
    margin-bottom: 3px;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  
.QASubmitForm_questionForm__1lCUO {
    padding: 1rem;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #c2c2c2;
}

.QASubmitForm_questionInput__1gI9r {
    border-radius: 0 !important;
    border: none;
    border-bottom: 1px solid var(--primary);
    flex-grow: 2;
}

.QASubmitForm_askButton__3UiS- {
    width: 4rem;
    border: none;
    background-color: transparent;
    color: grey;
    font-size: 2.5rem;
    transition: .3s;
}

.QASubmitForm_askButton__3UiS-:hover  {
    color: var(--primary);
    font-size: 2.5rem;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.QASubmitForm_loadingDots__1V5FL div {
    margin-top: 1rem;
    background-color: var(--primary);
}

.QASubmitForm_closedWrapper__2SXhK {
    display: flex;
    justify-content: center;
    align-items: center;
}
.QASubmitForm_closedTitle__2WU2u {
    border-bottom: 1px solid var(--primary);
    color: var(--primary);
}
#ColoredDot_dot__3KOGP {
    margin: .3rem 1rem;
    margin-right: 0;
    position: relative;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
} 

.ColoredDot_blinking__2CoIY {
    -webkit-animation: 1s :local(blink) 1s ease infinite;
    animation: ColoredDot_blink__zzwYK 1s  ease infinite;
    
  }
  
  @keyframes ColoredDot_blink__zzwYK {
    from, to {
      opacity: 0;
      visibility: hidden;
    }
    50% {
      opacity: 1;
      visibility: visible;
    }
  }
  
  @-webkit-keyframes ColoredDot_blink__zzwYK {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  } 

.QAAnswer_aContainer__33BXL {
    display: flex;
    justify-content: flex-Start;
    align-items: flex-end;
    flex-direction: column;
}

.QAAnswer_aBody__1ag-d {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 70%;
    margin: 1rem 0rem 1rem 1rem  ;
    padding: 1rem 2rem;
    padding-bottom: 2.2rem;
    width: fit-content;
    background-color:rgba(146, 21, 21, 01);
    border-radius: 1rem 0 0 1rem;
    color: white;
    font-size: 1.4rem;
}

.QAAnswer_aInfo__nmCWT {
    display: flex;
    margin-top: -1rem;
}

.QAAnswer_aAuthor__KZyWA {
    display: flex;
    margin: 0;
    margin-right: 1rem;
}

.QAAnswer_aTimestamp__3MBfV {
    display: flex;
    margin: 0;
    margin-right: 1rem;
}

.QAAnswer_answerInfoText__1xji4 {
    margin: 0;
    margin-top: .5rem;
}
#QAQuestion_qWrapper__3qM0G {
    margin:  0;
    padding: 0;
    display: flex;
   justify-content: flex-start;
    
}

#QAQuestion_leftBodyWrapper__18yTn, #QAQuestion_rightActionsWrapper__3YPOW  {
    display: flex;
    flex-direction: column;
}

#QAQuestion_leftBodyWrapper__18yTn {
    width: 50rem;
    padding: 1rem 0;
}

#QAQuestion_rightActionsWrapper__3YPOW {
   display: flex;
}


.QAQuestion_qContainer__3K4XU {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    
}



.QAQuestion_qBody__2lmBi {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 70%;
    margin: 1rem 1rem 1rem 0  ;
    padding: 1rem 2rem;
    padding-bottom: 2.2rem;
    width: fit-content;
    background-color: rgba(255, 255, 255, .6);
    border-radius: 0 1rem 1rem 0;
    color: white;
    font-size: 1.4rem;
}

.QAQuestion_userQuestion__3KpJR {
    background-color: rgba(100, 255, 100, .6);
}

.QAQuestion_qBodyContent__pLvMZ {
    display: flex;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.QAQuestion_qBodyContent__pLvMZ h1 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 600;
}

.QAQuestion_qActions__2In1f {
    padding: 0 1rem;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-self: flex-start;
    align-items: flex-start;
}



.QAQuestion_qInfo__2RQrm {
    margin-top: -.9rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-self: flex-start;
    color: rgba(0, 0, 0, .6);
    padding-top: .5rem;
}

.QAQuestion_qAuthor__5zPLO {
    display: flex;
   
}
.QAQuestion_qTimestamp__2QQFS {
    display: flex;
    margin-left: 1rem;
}

.QAQuestion_qAuthor__5zPLO p, .QAQuestion_qTimestampText__HEoPC {
    font-weight: 300;
    margin-top: .3rem;
}



.QAQuestion_notificationMessageContainer__2Ehgn {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    
}
.QAQuestion_notificationMessage__1RXRu {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: white;
    border-radius: 1rem;
}

.QAQuestion_notificationMessage__1RXRu p {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 400;
    padding: .4rem 1rem;
    color: #333;
}
#QABody_qaBody__1UU5G{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.QABody_noQuestionsWrapper__3zkNv {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: center;
    align-items: center;

}

.QABody_noQuestionsContent__2r8p_ {
    margin: 0;
    padding: 2rem;
    border: 0px solid white;
    border-radius: .5rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

}

.QABody_icon__3A8sA {
    font-size: 4rem;
}

.QABody_title__NgFJL {
    font-size: 2rem;
    margin-bottom: 0;
}

.QABody_text__3ODep {
    margin-top: .2rem;
    color: var(--primary);
    font-size: 1.6rem;
}
#QandA_qaWrapper__L15Jb {
    margin: 0;
    padding: 0;
    position: fixed;
    width: 90px;
    height: 90px;
    bottom: 40px;
    right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    border-radius: 50%;
    box-shadow: 0 0 .4rem rgba(0, 0, 0, .4);
    z-index: 1000;
    color: white;
    font-size: 3rem;
    transition: .3s;
    -webkit-transform:  rotate(0deg);
            transform:  rotate(0deg);
    z-index: 1000;
}

#QandA_qaWrapper__L15Jb span {
    font-size: 1.2rem;
}

#QandA_qaWrapper__L15Jb:hover {
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, .4);
    -webkit-transform:   scale(1.1);
            transform:   scale(1.1);
}

#QandA_qaWrapper__L15Jb:active {
    box-shadow: 0 0rem .2rem rgba(0, 0, 0, .6);
    -webkit-transform:  scale(.8);
            transform:  scale(.8);
}

.QandA_qaDrawWrapper__21aj- {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50rem;
    background-color: rgba(0, 0, 0, .8);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    box-shadow: 1rem 0 2rem rgba(0, 0, 0, .4);
    overflow: hidden;
}

.QandA_titleWrapper___G2aR {
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.QandA_title__1wopQ {
    font-style: 2.5rem;
    font-weight: 300;
    color: white;
}

.QandA_qaBodyWrapper__1Upms {
    position: relative;
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    justify-content: flex-end;
    overflow-y: scroll;
}

.QandA_editorWrapper__3x2jN {
    background-color: whitesmoke;
    /* height: 15rem; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.QandA_askQuestionForm__-i3Ff {
    margin: 0;
}

.QandA_closeBtn__1jrOT, .QandA_sessionBtn__3FqKx {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 1rem 0;
    cursor: pointer;
    transition: .3s;
}

.QandA_closeBtn__1jrOT {
    border-right: 1px solid white;
}

.QandA_closeBtn__1jrOT:hover, .QandA_sessionBtn__3FqKx:hover {
    background-color: var(--secondary);
}

.QandA_closeIcon__XNa36, .QandA_sessionIcon__AZXcu {
    font-size: 3rem;
}

.QandA_closeText__Qjcxk, .QandA_sessionText__2qmQw {
    margin: 0;
    font-size: 1.4rem;
}

.QandA_centerSpinner__kqv0t {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.QandA_closeWrapper__MkGO2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    color: white;
}

.QandA_loadingSpinner__3KxaW {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.QandA_loadingText__1WOQd {
    color: white;
    font-size: 1.4rem;
    font-weight: 300;
}

@media only screen and (max-width:500px) {
    #QandA_qaWrapper__L15Jb {   
        width: 60px;
        height: 60px;
        bottom: 20px;
        right: 20px;
        font-size: 2rem;
    }

    .QandA_qaDrawWrapper__21aj- {
        width: 100%;
    }

    .QandA_closeIcon__XNa36, .QandA_sessionIcon__AZXcu {
        font-size: 2rem;
    }
    
    .QandA_closeText__Qjcxk, .QandA_sessionText__2qmQw {
        font-size: 1.1rem;
    }
}
.NoSessionsNotice_noticeWrapper__3xoQH {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.NoSessionsNotice_noticeContent__1Nzxx {
    padding: 2rem;
    max-width: 30rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid white;
    border-radius: .5rem;
}

.NoSessionsNotice_icon__2saGx {
    font-size: 3.6rem;
}

.NoSessionsNotice_title__36Rg3 {
    margin: 1rem 0;
    font-size: 2.6rem;
    font-weight: 300;
}

.NoSessionsNotice_text__1UV9Y{
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 0;
}
.QASessionItem_itemWrapper__2smBR {
    margin: 1rem;
    padding: 1rem;
    background-color: var(--primary);
    color: white;
    border-radius: .5rem;
    opacity: .8;
    transition: .3s;
    cursor: pointer;
}

.QASessionItem_itemWrapper__2smBR:hover {
   opacity: 1;
}
   
#QASessionList_sessionListWrapper__386oZ {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.QASessionList_sessionListContainer__4v8Bq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}
#Polls_pollWrapper__pR8iV{
    margin: 0;
    padding: 0;
    position: fixed;
    width: 90px;
    height: 90px;
    bottom: 140px;
    right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    border-radius: 50%;
    box-shadow: 0 0 .4rem rgba(0, 0, 0, .4);
    z-index: 1000;
    color: white;
    font-size: 3rem;
    transition: .3s;
    -webkit-transform:  rotate(0deg);
            transform:  rotate(0deg);
    z-index: 900;
}

.Polls_pollBody__1z9PH {
    display: flex;
    flex-direction: column;

}

.Polls_listTitle__1d1ao {
    color: var(--primary);;
}



#Polls_pollWrapper__pR8iV span {
    font-size: 1.2rem;
}

#Polls_pollWrapper__pR8iV:hover {
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, .4);
    -webkit-transform:   scale(1.1);
            transform:   scale(1.1);
}

#Polls_pollWrapper__pR8iV:active {
    box-shadow: 0 0rem .2rem rgba(0, 0, 0, .6);
    -webkit-transform:  scale(.8);
            transform:  scale(.8);
}

.Polls_pollDrawWrapper__1qsCs {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50rem;
    background-color: rgba(0, 0, 0, .8);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    box-shadow: 1rem 0 2rem rgba(0, 0, 0, .4);
    overflow: hidden;
    
}

.Polls_titleWrapper__RBdFH {
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Polls_title__EkHkA {
    font-style: 2.5rem;
    font-weight: 300;
    color: white;
}

.Polls_pollBodyWrapper__FKDEa {
    position: relative;
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    justify-content: center;
    overflow-y: scroll;
}

.Polls_editorWrapper__29ghs {
    background-color: whitesmoke;
    /* height: 15rem; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Polls_askQuestionForm__3zxn8 {
    margin: 0;
}

.Polls_closeBtn__3PX_b, .Polls_sessionBtn__xL-SM {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 1rem 0;
    cursor: pointer;
    transition: .3s;
}

.Polls_closeBtn__3PX_b {
    border-right: 1px solid white;
}

.Polls_closeBtn__3PX_b:hover, .Polls_sessionBtn__xL-SM:hover {
    background-color: var(--secondary);
}

.Polls_closeIcon__2efhu, .Polls_sessionIcon__Et5T4 {
    font-size: 3rem;
}

.Polls_closeText__34sjW, .Polls_sessionText__3nxtz {
    margin: 0;
    font-size: 1.4rem;
}

.Polls_centerSpinner__16eFh {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Polls_closeWrapper__12aba {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    color: white;
}

.Polls_loadingSpinner__Y6YnN {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Polls_loadingText__1pcR8 {
    color: white;
    font-size: 1.4rem;
    font-weight: 300;
}

@media only screen and (max-width:500px) {
    #Polls_pollWrapper__pR8iV {   
        width: 60px;
        height: 60px;
        bottom: 90px;
        right: 20px;
        font-size: 2rem;
    }

    .Polls_pollDrawWrapper__1qsCs {
        width: 100%;
    }

    .Polls_closeIcon__2efhu, .Polls_sessionIcon__Et5T4 {
        font-size: 2rem;
    }
    
    .Polls_closeText__34sjW, .Polls_sessionText__3nxtz {
        font-size: 1.1rem;
    }
}
.NoSessionsNotice_noticeWrapper__SO26o {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.NoSessionsNotice_noticeContent__3l9YE {
    padding: 2rem;
    max-width: 30rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid white;
    border-radius: .5rem;
}

.NoSessionsNotice_icon__1A3j2 {
    font-size: 3.6rem;
}

.NoSessionsNotice_title__1vZx2 {
    margin: 1rem 0;
    font-size: 2.6rem;
    font-weight: 300;
}

.NoSessionsNotice_text__2t6Fp{
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 0;
}
#PollListItem_itemWrapper__3vO0M {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 1rem 0 ;
    background-color: white;
    padding: 1rem 2rem;
    border-radius: .5rem;
    border: .1rem solid #ccc;
    box-shadow: 0 .1rem .8rem rgba(0, 0, 0, .1);
    transition: .3s;
}

#PollListItem_itemWrapper__3vO0M:hover {
    background-color: whitesmoke;
}
#PollListItem_itemWrapper__3vO0M h1 {
    color: grey;
    margin: 0;
    font-weight: 300;
}

.PollListItem_icon__1n4gL {
    font-size: 2rem;
    color: var(--primary);
    margin-right: 2rem;
}
.PollList_pollList__Lcnkm {
    list-style: none;
    padding-left: 0;
    margin: 2rem;
}

.PollList_listTitle__3LU8V {
    color: var(--primary);
}
.PollChart_pollChartContainer__3L1aw {
    position: relative;
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PollChart_poll-no-data-notice__1I4AG {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.PollChart_poll-no-data-title__UPkic {
    color: white;
    margin: 0;
    font-size: 3rem;
    font-weight: 300;
}
.PollChart_poll-no-data-text__2UONT {
    margin: 0;
    margin-top: 1rem;
    color: white;
    font-size: 1.4rem;
    max-width: 300px;
}

.PollChart_tooltipWrapper__1yVN2 {
    background: var(--secondary);
    padding: 1rem;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 .3rem .6rem rgba(0, 0, 0, .5);
    border: .1rem solid grey;
}
.PollChart_title__2Zcvv {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 500;
}

.PollChart_value__LcXpd {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 900;
}

.PollChart_pieText__3JnZP {
    font-weight: 500;
    font-size: 1.6rem;
}
.PollItem_pollItemWrapper__119oZ {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 300px;
    max-width: 100vw;
    transition: .3s;
}

.PollItem_pollBodyContiner__2-NzN {
    padding:  2rem;
    padding-left: 0;
    min-width: 90%;
    display: flex;
}

.PollItem_footer__2Bm-v {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: #fff;
    border-radius: 0 0 .5rem .5rem ;
    
}

.PollItem_headerMenuBar__1xbUD {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PollItem_showMenuButton__2RYny {
    padding: 0 1rem;
    height: 2rem;
    font-size: 3rem;
    color: #fff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
}

.PollItem_menuRefresh__3T1M4 {
    padding: 1rem;
    font-size: 2rem;
    color: #fff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    transition: 1s;
}

.PollItem_menuRefresh__3T1M4:hover {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg);
}

.PollItem_poll-item-menu__16_MV {
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    transition: .3s;
    height: 0;
}

.PollItem_pim-open__2-yJ9 {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
    background-color: rgba(0, 0, 0, .9);
    z-index: 500;
}

.PollItem_pim-actions__alm98 {
    display: flex;
    justify-content: space-around;
}

.PollItem_pollItemLoading__13e_x {
    position: absolute;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.PollItem_pollItemLoading-dots__1wgTV {
    height: 1.6rem;
}
.PollItem_pollItemLoadingDots__2aXqe div {
    background-color: var(--primary);
}

.PollItem_LoadingDotsH1__Fx5YM, .PollItem_LoadingDotsP__fgbhU {
    margin: 0;
    color: white;
}
#content-container {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    background: whitesmoke;
    padding: 20px;
    border-radius: 8px;
    justify-content: flex-start;  
}

.cc-outer-content {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--primary);
    border-radius: 8px;
    width: 100%;
}

.trans-details-title {
    margin: -1rem 2rem .5rem 1rem;
    background-color: whitesmoke;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 10px;
    font-size: 1.6rem;
}

.cc-inner-content {
    padding: 10px;
    display: flex;
    color: #333;
}
.PollVote_votingWrapper__3xQpq {
    display: flex;
    justify-content: center;
    color: var(--primary);
    min-width: 25rem;
}

.PollVote_buttonContainer__3ZmNq {
    margin: 0;
    display: flex;
    flex-direction: column;
}

.PollVote_voteText__2MS0S {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.PollVote_voteBtnContainer__2kY6K {
display: flex;
align-items: center;
}


.PollVote_voteButton__UHjb2 {
    margin: .5rem;
    flex-grow: 2;
}
.PollVote_voteValue__2VblB {
    width: 15%;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
}

.PollVote_thanksContainer__1xffv {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.PollVote_check__29QKE {
    font-size: 8rem;
    color: green;
    background-color: white;
    border-radius: 50%;
    line-height: 7rem;
    margin: 2rem;
}

.PollVote_cross__3ynRl {
    font-size: 8rem;
    color: red;
    background-color: white;
    border-radius: 50%;
    line-height: 7rem;
    margin: 2rem;
}

.PollVote_message__1_U6v {
    font-size: 2rem;
    color: white;
    text-align: center;
    font-weight: 200;
    margin: .2rem;
}

.PollVote_subMessage__2bB5n {
    font-size: 1.4rem;
    text-align: center;
    margin: 0;
    margin-bottom: 3rem;
    color: var(--primary);
}

.PollVote_votedValue__1Ds4D {
    margin-top: 0;
    padding: 0 2rem;
    color: white;
    font-size: 2rem;
}

.PollVote_contentContainerWrapper__Ewg-6 {
    width: 100%;
    background-color: transparent !important;
    color: white;
    border: 0 !important;
}
.PollVote_thanksContainer__1xffv {
    color: white;
}
#NewsMarquee_MarqueeWrapper__DA1X1 {
    margin: 0;
    padding: 1rem 0;
    background-color: var(--primary);
    color: white;
    font-size: 1.4rem;
    font-weight: 800;
    min-width: 100vw;
    z-index: 999;
}

.NewsMarquee_newsItem__LIITe {
    margin-right: 5rem;
}

.NewsMarquee_seperator__2VbIy {
  margin: 0 1rem 0 5rem;
}

.NewsMarquee_newsText__2ACLe {
    margin: 0;
}


@media (min-width:1920px) {
    #NewsMarquee_MarqueeWrapper__DA1X1 {
        font-size: 1.8rem;
    }
}

@media (min-width:1340px) {
    #NewsMarquee_MarqueeWrapper__DA1X1 {
        font-size: 1.6rem;
    }
}

@media (max-width:768px) {
    #NewsMarquee_MarqueeWrapper__DA1X1 {
        font-size: 1.2rem;
        font-weight: 600;
    }
}
.Event_pageWrapper__19nQt {
    margin: 0 auto;
    padding: 0;
    margin-bottom: 5.6rem;
    width: 100%;
    min-height: 500px;
}

.Event_centerSpinner__1hUg2 {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width:768px) {
    .Event_pageWrapper__19nQt {
        margin-bottom: 7rem;
    }
}
#Agenda_agendaWrapper__2omQo{
    margin: 0;
    padding: 0;
    position: fixed;
    width: 90px;
    height: 90px;
    bottom: 240px;
    right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    border-radius: 50%;
    box-shadow: 0 0 .4rem rgba(0, 0, 0, .4);
    z-index: 1002;
    color: white;
    font-size: 3rem;
    transition: .3s;
    -webkit-transform:  rotate(0deg);
            transform:  rotate(0deg);
    z-index: 900;
}

.Agenda_agendaBody__3XmrL {
    display: flex;
    flex-direction: column;

}

.Agenda_listTitle__IVeD4 {
    color: var(--primary);;
}



#Agenda_agendaWrapper__2omQo span {
    font-size: 1.2rem;
}

#Agenda_agendaWrapper__2omQo:hover {
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, .4);
    -webkit-transform:   scale(1.1);
            transform:   scale(1.1);
}

#Agenda_agendaWrapper__2omQo:active {
    box-shadow: 0 0rem .2rem rgba(0, 0, 0, .6);
    -webkit-transform:  scale(.8);
            transform:  scale(.8);
}

.Agenda_agendaDrawWrapper__34Shy {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50rem;
    background-color: rgba(0, 0, 0, .8);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    box-shadow: 1rem 0 2rem rgba(0, 0, 0, .4);
    overflow: hidden;
    
}

.Agenda_editorWrapper__3e74y {
    background-color: whitesmoke;
    /* height: 15rem; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.Agenda_titleWrapper__2GXXq {
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Agenda_title__15Kw1 {
    font-style: 2.5rem;
    font-weight: 300;
    color: white;
}

.Agenda_agendaBodyWrapper__1PeVw {
    position: relative;
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: scroll;
}

.Agenda_askQuestionForm__1A4EA {
    margin: 0;
}

.Agenda_closeBtn__mkqXx {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 1rem 0;
    cursor: pointer;
    transition: .3s;
}

.Agenda_closeBtn__mkqXx {
    border-right: 1px solid white;
}

.Agenda_closeBtn__mkqXx:hover {
    background-color: var(--secondary);
}

.Agenda_closeIcon__153dA {
    font-size: 3rem;
}

.Agenda_closeText__3pm8F {
    margin: 0;
    font-size: 1.4rem;
}

.Agenda_centerSpinner__3Zw32 {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Agenda_closeWrapper__vhB4N {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    color: white;
}

.Agenda_loadingSpinner__1mRcB {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Agenda_loadingText__W-hUh {
    color: white;
    font-size: 1.4rem;
    font-weight: 300;
}

@media only screen and (max-width:500px) {
    #Agenda_agendaWrapper__2omQo {   
        width: 60px;
        height: 60px;
        bottom: 160px;
        right: 20px;
        font-size: 2rem;
    }

    .Agenda_agendaDrawWrapper__34Shy {
        width: 100%;
    }

    .Agenda_closeIcon__153dA {
        font-size: 2rem;
    }
    
    .Agenda_closeText__3pm8F {
        font-size: 1.1rem;
    }
}
.AgendaList_ListWrapper__S6jg6 {
    margin: 1rem;
}

.AgendaList_agendaItemWrapper__3-_nH {
    margin: 2rem 0;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 1);
    color: white;
}

.AgendaList_imageWrapper__3QXQ9 {
    display: flex;
    align-items: flex-start;
    justify-content:  flex-start;
    margin: 1rem;
    margin-bottom: 0;
}

.AgendaList_image__1uThZ {
    width: 5rem;
    height: 5rem;
    background-color: var(--primary);
    border-radius: 50%;
}

.AgendaList_headerInfoWrapper__3CGgi {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.AgendaList_itemHeader__JQ1ZE {
    margin: 0;
    padding: 0;
    display: flex;

}

.AgendaList_title__3IYov {
    font-weight: 600;
    color: #333;
    margin: 0;
}

.AgendaList_subtitle__2gINM {
    font-weight: 600;
    color: var(--primary);
    margin: 0;
}

.AgendaList_timesWrapper__39TIi {
    margin: 1rem 0 0rem 0;
    background-color: white;
    color: #333;
    padding: .5rem;
    font-size: 1.4rem;
}

.AgendaList_times__1J6aS {
    color: #333;
    margin-left: 1rem;
}

.AgendaList_times__1J6aS span {
    color: var(--primary);
    font-weight: 500;
    margin-left: 1rem;
}

.AgendaList_description__8wrUc {
    margin: 0;
    border-top: 1px #ccc solid;
    padding: 1rem;
}

.AgendaList_desc__uiLS3 {
    font-size: 1.4rem;
    color: #333;
}
.Holding_pageWrapper__3LS-z {
    margin: 0 auto;
    padding: 0;
    margin-bottom: 5.6rem;
    width: 100%;
    min-height: 500px;
}

.Holding_centerSpinner__1Doo5 {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width:768px) {
    .Holding_pageWrapper__3LS-z {
        margin-bottom: 7rem;
    }
}
#CountDown_countdownWrapper__YcSoK {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    color: white;
    font-size: 2.5vw;
    width: 100%;
    margin-top: 2rem;
}

#CountDown_countdownWrapper__YcSoK span {
    margin-top: .5vw;
}

.CountDown_counter__3T77W {
    text-align: center;
    margin: 0;
   width: 4vw;
   text-align: center;
   width: 100%;
}

.CountDown_counterLabel__2Lz0K {
    margin: 0;
    text-align: center;
    font-size: 1vw;
}

.CountDown_days__3iuq3,
.CountDown_hours__21yLB,
.CountDown_mins__2R2pt,
.CountDown_secs__1nZ60 {
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 5.5vw;
}

@media  screen and (max-width:768px) {
    #CountDown_countdownWrapper__YcSoK {
        font-size: 4.5vw;
    }

    #CountDown_countdownWrapper__YcSoK span {
        margin-top: 1vw;
    }

    .CountDown_days__3iuq3,
    .CountDown_hours__21yLB,
    .CountDown_mins__2R2pt,
    .CountDown_secs__1nZ60 {
        width: 8.5vw;
    }
  }


  @media  screen and (max-width:568px) {
   
  }
#HoldingHero_hero__1cN_h {
    width: 100%;
    min-height: 50rem;
    margin: 0;
    padding: 0;
   background-position: center;
   background-repeat: no-repeat;background-size: cover;
   position: relative;
   display: flex;
}

#HoldingHero_bgOverlay___HLt9 {
 position: absolute;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
 background-color: var(--primary);
 opacity: .7;
 z-index: 1;
}

.HoldingHero_content__3Z5mF {
    margin: 0 auto;
    position: relative;
    z-index: 2;
    max-width: 192rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.HoldingHero_title__H7vus {
    margin-bottom: 0;
    font-size: 5vw;
    line-height: 5.1vw;
    color: white;
    font-weight: 900;
}

.HoldingHero_subTitle__ui3p1 {
    margin: 0;
    padding: 0;
    font-size: 3vw;
    line-height: 3.1vw;
}


.HoldingHero_buttonSecondary__36smn {
    margin: 2vw;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    border-width: 1px;
    font-weight: 600;
    font-size: 1vw;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    transition: all .35s;
    color: #212529 !important;
    background-color: #f0b505;
    border: .1rem solid #7B6715;
    box-shadow: 0 .3rem .3rem rgba(0, 0, 0, .4);
    
}

.HoldingHero_buttonSecondary__36smn span {
    margin-left: 1rem;
}

.HoldingHero_buttonSecondary__36smn:hover {
    box-shadow: 0 .6rem .6rem rgba(0, 0, 0, .2);
    background-color: #ffcc00;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .HoldingHero_text__CFlMP {
      color: white;
      font-size: 2rem;
      max-width: 50vw;
      text-align: center;
      font-weight: 400;
      margin-bottom: 5rem;
  }


  @media  screen and (max-width:1920px) {
    .HoldingHero_buttonSecondary__36smn {
        font-size: 2rem;
    }
  }

  @media  screen and (max-width:768px) {
    .HoldingHero_buttonSecondary__36smn {
        font-size: 2rem;
    }

    .HoldingHero_title__H7vus {
        font-size: 6vw;
        line-height: 6.1vw;
    }
    .HoldingHero_subTitle__ui3p1 {
        font-size: 4vw;
        line-height: 4.1vw;
    }
  }


  @media  screen and (max-width:568px) {
    .HoldingHero_buttonSecondary__36smn {
        width: 95%;
    }
    .HoldingHero_title__H7vus {
        font-size: 8vw;
        line-height: 8.1vw;
    }
    .HoldingHero_subTitle__ui3p1 {
        font-size: 6vw;
        line-height: 6.1vw;
    }

    .HoldingHero_text__CFlMP {
        max-width: 95vw;
        font-size: 1.4rem;
    }
  }
.top-links {
    list-style: none;
    margin:0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    font-size: 1.6rem;
  }

  .top-links li {
    margin: 1rem;
    display: flex;
    align-items: center;
    color: var(--primary);
  }

  .top-links a, .top-links__logout {
    color: var(--primary);
    text-decoration: none;
    
  }

  .top-links a {
    padding: 0.5rem;
  }


.top-links a:hover,
.top-links a.active, 
.top-links__logout:hover
 {
  
  color: #333;
  
}

.top-links__logout:active{
  color: #818181;
}

.top-links__logout {
    cursor: pointer;
    background: transparent;
    font: inherit;
    border: none;
  }
  
  .top-links__logout:focus {
    outline: none;
    color: #818181;
  }
  
  .top-links__logout:hover,
  .top-links__logout:active {
    color:#333;
  }
  

  @media only screen and (max-width: 768px) {
    .top-links a, .top-links__logout {
      color:white;
      
    }
  }
.menu {
  display: flex;
  justify-content: center;
  margin: 0;
}
.dropdown {
  padding: 0px 2rem ;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--primary);
  position: relative;
  font-size: 1.6rem;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  z-index: 100;
  min-width:20rem;
}
.dropdown:hover {
  color: #333;
  cursor: pointer;
}
.dropdown:hover .dropdown_menu li {
  display: block;
}
.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100px;
  width: 100%;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  z-index: -1;
  
}
.dropdown_menu li {
  display: none;
  color: #fff;
  background-color: var(--primary);
  padding: 10px 20px;
  font-size: 14px;
  opacity: 0;
  box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .3);
}
.dropdown_menu li:hover {
  background-color: var(--secondary);
}
.dropdown:hover .dropdown_menu--animated {
  display: block;
}
.dropdown_menu--animated {
  display: none;
}
.dropdown_menu--animated li {
  display: block;
  opacity: 1;
}
.lang-sub-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  display: none;
}
.dropdown:hover .dropdown_menu--animated {
  display: block;
}

.dropdown_menu li {
  display: none;
  color: white;
  
  background-color: var(--primary);
  padding: 10px 20px;
  font-size: 16px;
  opacity: 0;
  transition: .3s;;
}

.dropdown_menu li:hover {
    background-color: var(--secondary);
}

.dropdown:hover .dropdown_menu li {
  display: block;
  
}
.dropdown_menu-2 .dropdown_item-1 {
    
    -webkit-transform-origin: top center;
    
            transform-origin: top center;
    margin-top: .7rem;
    -webkit-animation: rotateX 300ms 60ms ease-in-out forwards;
            animation: rotateX 300ms 60ms ease-in-out forwards;
  }
  .dropdown_menu-2 .dropdown_item-2 {
    border-radius: 0 ;
    -webkit-transform-origin: top center;
            transform-origin: top center;
    -webkit-animation: rotateX 300ms 120ms ease-in-out forwards;
            animation: rotateX 300ms 120ms ease-in-out forwards;
  }
  .dropdown_menu-2 .dropdown_item-3 {
    -webkit-transform-origin: top center;
            transform-origin: top center;
    border-radius: 0 0 5px 5px;
    -webkit-animation: rotateX 300ms 180ms ease-in-out forwards;
            animation: rotateX 300ms 180ms ease-in-out forwards;
  }
  .dropdown_menu-2 .dropdown_item-4 {
    -webkit-transform-origin: top center;
            transform-origin: top center;
    -webkit-animation: rotateX 300ms 240ms ease-in-out forwards;
            animation: rotateX 300ms 240ms ease-in-out forwards;
  }
  .dropdown_menu-2 .dropdown_item-5 {
    -webkit-transform-origin: top center;
            transform-origin: top center;
    -webkit-animation: rotateX 300ms 300ms ease-in-out forwards;
            animation: rotateX 300ms 300ms ease-in-out forwards;
  }

  .lang-title {
    margin-left: .5rem;
  }
@-webkit-keyframes rotateX {
  0% {
    opacity: 0;
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
  }
  50% {
    -webkit-transform: rotateX(-20deg);
            transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}
@keyframes rotateX {
  0% {
    opacity: 0;
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
  }
  50% {
    -webkit-transform: rotateX(-20deg);
            transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}



.lang-name {
    margin: 0 .5rem;
}

@media only screen and (max-width: 768px) {
  .dropdown {
    color: white;
  }
}
nav {
  display: inline-flex;
  float: right;
}

p {
  color: white;
  display: inline;
}

.header-container {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 5;
}

.header-container__shell {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.header-conatiner__top,
.header-conatiner__nav_top {
  margin: 0;
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

}

.wrapper {
  display: flex;
  align-items: center;
}

.header-conatiner__top {
  display: flex;
  justify-content:flex-end;
  align-items: center;
  height: 30px;
  z-index: 1000;
}

.header-conatiner__nav_top {
  height: 30px;
  background-color: var(--secondary);
  z-index: 999;
}

.header-container__info {
  display: flex;
  justify-content: center;
  padding: 3px 15px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}



.header-brand-text {
  display: none;
}

.header-container__title {
  color: white;
}

.header-container__nav {
  list-style: none;
}

.header-container__link {
  display: inline;
}

.main-navigation__menu-btn {
  margin: 8px 3px;
  width: 2.5rem;
  height: 2rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 2rem;
  height: 2px;
  background: white;
}

.main-navigation__header-nav {
  display: flex;
}

.header-container__brand {
  width: 50%;
  display: grid;
  font-family: 'Roboto', sans-serif;
}

@media only screen and (max-width: 768px) {
  .header-conatiner__top {
    background-color: var(--primary);
    color: white;
    box-shadow: 0 .3rem .3rem rgba(0, 0, 0, .4);
  }
}



@media only screen and (max-width: 450px) {
  .header-container__info {
    width: 100%;
    font-size: 1.5rem;
  }
  .header-email {
    width: 100%;
    text-align: center;
  }
}

footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 500;
    background-color:var(--secondary);
    box-shadow: 0px -2px 3px rgba(0, 0, 0, .3); 
}

.main-footer__shell {
    margin: 0 auto;
    padding: 0 3rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1756px;
   
}

.main-footer__brand {
    padding: 2%;

}

.main-footer__text {
    padding: 1.5rem;
    max-width: 50vw;
}

.main-footer-text-p {
    display: block;
    margin: 1rem 0 ;
    font-size: 1.2rem;
}

.jobNumber {
    font-weight: 800;
}

.ft-title{
    font-size: 1.3rem;
}

.main-footer__links {
    padding: 1.5rem;
    float: left;
}

@media (max-width: 391px) {
    .main-footer__links {
        margin:0;
        width: 100%;
    }
    .main-footer__text {
        max-width: 95vw;
    }
  }

.main-footer__contact {
    
    margin-right: 1rem;
    padding: 1.5rem;
    float: right;
}

.main-footer__bottom {
    margin: 0;
    padding: .5rem;
    padding-top: 1.5rem;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.4) !important;
    background-color: var(--secondary);
    font-size: 1.4rem;

}
.main-footer__bottom p {
  color: rgba(255, 255, 255, 0.4) !important;
}
.video {
  width: 50%;
 }

 .spinnerWithText {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinnerWithText p {
  font-size: 1.2rem;
  color: #333;
}
