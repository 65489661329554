.branding {
    width: 100% ;
    height: 100%;
    display: flex; 
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }

  .branding_inner_wrapper {
    width: 100%;
    max-width: 110rem;
    display: flex;
    justify-content: flex-start;
  }
  
  .branding .branding_inner_wrapper img {
    display: block;
    padding: 2px;
    width: 100%;
    height: 100%;
    object-fit: contain;
   
    
  }

  .branding_inner_title {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .branding_inner_title h1 {
    margin: 0;  
    font-size: 3rem;
    color: var(--primary);
  }

  .branding_inner_title h2 {
    margin: 0;  
    font-size: 2rem;
    color: var(--secondary);
  }