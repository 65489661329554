#MarqueeWrapper {
    margin: 0;
    padding: 1rem 0;
    background-color: var(--primary);
    color: white;
    font-size: 1.4rem;
    font-weight: 800;
    min-width: 100vw;
    z-index: 999;
}

.newsItem {
    margin-right: 5rem;
}

.seperator {
  margin: 0 1rem 0 5rem;
}

.newsText {
    margin: 0;
}


@media (min-width:1920px) {
    #MarqueeWrapper {
        font-size: 1.8rem;
    }
}

@media (min-width:1340px) {
    #MarqueeWrapper {
        font-size: 1.6rem;
    }
}

@media (max-width:768px) {
    #MarqueeWrapper {
        font-size: 1.2rem;
        font-weight: 600;
    }
}