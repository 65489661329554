.menu {
  display: flex;
  justify-content: center;
  margin: 0;
}
.dropdown {
  padding: 0px 2rem ;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--primary);
  position: relative;
  font-size: 1.6rem;
  perspective: 1000px;
  z-index: 100;
  min-width:20rem;
}
.dropdown:hover {
  color: #333;
  cursor: pointer;
}
.dropdown:hover .dropdown_menu li {
  display: block;
}
.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100px;
  width: 100%;
  perspective: 1000px;
  z-index: -1;
  
}
.dropdown_menu li {
  display: none;
  color: #fff;
  background-color: var(--primary);
  padding: 10px 20px;
  font-size: 14px;
  opacity: 0;
  box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .3);
}
.dropdown_menu li:hover {
  background-color: var(--secondary);
}
.dropdown:hover .dropdown_menu--animated {
  display: block;
}
.dropdown_menu--animated {
  display: none;
}
.dropdown_menu--animated li {
  display: block;
  opacity: 1;
}
.lang-sub-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  perspective: 1000px;
  display: none;
}
.dropdown:hover .dropdown_menu--animated {
  display: block;
}

.dropdown_menu li {
  display: none;
  color: white;
  
  background-color: var(--primary);
  padding: 10px 20px;
  font-size: 16px;
  opacity: 0;
  transition: .3s;;
}

.dropdown_menu li:hover {
    background-color: var(--secondary);
}

.dropdown:hover .dropdown_menu li {
  display: block;
  
}
.dropdown_menu-2 .dropdown_item-1 {
    
    transform-origin: top center;
    margin-top: .7rem;
    animation: rotateX 300ms 60ms ease-in-out forwards;
  }
  .dropdown_menu-2 .dropdown_item-2 {
    border-radius: 0 ;
    transform-origin: top center;
    animation: rotateX 300ms 120ms ease-in-out forwards;
  }
  .dropdown_menu-2 .dropdown_item-3 {
    transform-origin: top center;
    border-radius: 0 0 5px 5px;
    animation: rotateX 300ms 180ms ease-in-out forwards;
  }
  .dropdown_menu-2 .dropdown_item-4 {
    transform-origin: top center;
    animation: rotateX 300ms 240ms ease-in-out forwards;
  }
  .dropdown_menu-2 .dropdown_item-5 {
    transform-origin: top center;
    animation: rotateX 300ms 300ms ease-in-out forwards;
  }

  .lang-title {
    margin-left: .5rem;
  }
@keyframes rotateX {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  50% {
    transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}



.lang-name {
    margin: 0 .5rem;
}

@media only screen and (max-width: 768px) {
  .dropdown {
    color: white;
  }
}